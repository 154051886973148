import React from "react";
import styled from "@emotion/styled";
import { VideoFeature, VideoPortent } from "./VideoFeature";

const WrapperContainer = styled.div`
  .container {
    max-width: 800px;
    width: 95%;
    margin: 3em auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 0px 8px;

    border-radius: 20px;
    gap: 1.5em;
    @media (max-width: 732px) {
      max-width: 400px;
    }
  }
  .portentContent {
    max-width: 800px;
    width: 95%;
    margin: 3em auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .portentContent-video {
    grid-column: 3 / span 2;
    & .frame {
      max-width: 225px;
      height: auto !important;
      border-radius: 10px;
    }
    @media (max-width: 732px) {
      grid-column: 1 / span 4;
      & .frame {
        height: 100% !important;
        width: unset !important;
      }
    }
  }
  .portentContent-text {
    grid-column: 1 / span 2;
    @media (max-width: 732px) {
      grid-column: 1 / span 4;
      max-width: 400px;
      margin: 0 auto;
    }
  }
  div:nth-of-type(1) {
    order: ${(props) => (props.order ? props.order : 0)};
    @media (max-width: 732px) {
      order: 0;
    }
  }
  .container-image {
    max-width: 250px;
    margin: 0 auto;
  }
  .container-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em 1em;
    & h1 {
      font-weight: 500;
      margin: 0.5em 0;
      font-size: clamp(1.4rem, 2.5vw, 1.8rem);
      & strong {
        color: #fbb400;
      }

      @media (max-width: 382px) {
        & br {
          display: none;
        }
      }
    }
    @media (max-width: 732px) {
      &.spacial {
        & br {
          display: none;
        }
      }
    }

    & p {
      color: #585858;
      font-weight: 400;
      margin: 0.5em 0;
      font-size: clamp(1rem, 2.5vw, 1.1rem);
    }
    & h5 {
      margin: 0;
      font-weight: 300;
      font-size: clamp(0.9rem, 2.5vw, 0.9rem);
    }
    @media (max-width: 592px) {
      text-align: center;
      & .app-store {
        justify-content: center;
      }
    }
  }
`;
const FeatureContent = ({ header, subTitle, videoName, order }) => {
  return (
    <WrapperContainer order={order}>
      <div className="container">
        <VideoFeature videoName={videoName} border={order ? true : false} />

        <div className="container-text">
          {header}
          {subTitle}
        </div>
      </div>
    </WrapperContainer>
  );
};

export const PortentContent = ({ header, subTitle, videoName, order }) => {
  return (
    <WrapperContainer order={order}>
      <div className="portentContent">
        <div className="portentContent-video">
          <VideoPortent videoName={videoName} noBorder={false} />
        </div>

        <div className="portentContent-text">
          <div className="container-text spacial">
            {header}
            {subTitle}
          </div>
        </div>
      </div>
    </WrapperContainer>
  );
};

export default FeatureContent;
