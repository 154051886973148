import React from "react";
import styled from "@emotion/styled";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { useStaticQuery, graphql } from "gatsby";

const WrapperContainer = styled.div`
  width: 100%;
  margin: 2em auto;
  color: #fff;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-transform: capitalize;
  text-align: center;
  ::before {
    content: " ";
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    background-image: ${(props) => `url(${props.background})`};
  }

  & h1 {
    z-index: 1;
    font-size: clamp(1.8rem, 3vw, 2rem);
    font-weight: 700;
  }
  & strong {
    font-size: clamp(2.5rem, 4vw, 2.5rem);
    font-weight: 800;
  }
`;

const TitleQuote = () => {
  const { t } = useTranslation();
  const { titleBg } = useStaticQuery(graphql`
    query {
      titleBg: file(relativePath: { eq: "svg/TitleBg.svg" }) {
        id
        publicURL
      }
    }
  `);

  return (
    <WrapperContainer background={titleBg.publicURL}>
      <h1>
        <Trans components={[<br />, <strong />]}>
          {t("app.What you will love")}
        </Trans>
      </h1>
    </WrapperContainer>
  );
};
export default TitleQuote;
