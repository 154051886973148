import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
// import { DesktopWindowsRounded } from "@material-ui/icons";
import GoogleStoreLogo from "../../../images/svg/GoogleStoreLogo.svg";
import AppStoreLogo from "../../../images/svg/AppStoreLogo.svg";
import { APPLE_STORE, GOOGLE_STORE } from "../../../utils/config";

const AppStore = ({ desktop }) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="app-store"
        style={{
          display: "flex",
          gap: "1em",
        }}
      >
        <a
          aria-label="apple-store-link"
          href={`${APPLE_STORE}`}
          style={{ textDecoration: "none" }}
        >
          <AppStoreLogo style={{ maxWidth: 240, width: "100%" }} />
        </a>
        <a
          aria-label="google-store-link"
          href={`${GOOGLE_STORE}`}
          style={{ textDecoration: "none" }}
        >
          <GoogleStoreLogo style={{ maxWidth: 240, width: "100%" }} />
        </a>
      </div>

      {/* {desktop ? (
        <div className="download-container">
          <div className="body-text-download">{t("Download free today")}</div>
          <div className="divider-container" />
          <div className="desktop-container">
            <DesktopWindowsRounded style={{ maxWidth: 25, width: "100%" }} />

            <a href={`${PIXMAKER_APP}/login`}>{t("desktop")}</a>
          </div>
        </div>
      ) : (
        <div className="body-text-download">{t("Download free today")}</div>
      )} */}
      <div className="body-text-download">{t("Download free today")}</div>
    </>
  );
};

export default AppStore;
