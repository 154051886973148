import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { BannerContainer } from "./Style/index";
import AppStore from "./AppStore";
import { Logo } from "../../layout/Logo";
import VideoBannerComponent from "./VideoMobile/VideoBanner";

const AppBanner = () => {
  const { t } = useTranslation();
  const { elementBackground } = useStaticQuery(graphql`
    query {
      elementBackground: file(relativePath: { eq: "svg/MobileBanner.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <BannerContainer background={elementBackground.publicURL}>
      <div
        style={{
          paddingTop: "1.5em",
        }}
      >
        <VideoBannerComponent />
      </div>

      <div className="wrapper-text">
        <Logo style={{ maxWidth: 150 }} />
        <h1>KUU Creator</h1>
        <h2>
          <div className="sub-text">
            <Trans components={[<br />]}>{t("app.banner")}</Trans>
          </div>
        </h2>

        <AppStore desktop={true} />
      </div>
    </BannerContainer>
  );
};

export default AppBanner;
