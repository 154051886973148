import React from "react";
import styled from "@emotion/styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { GatsbyImage } from "gatsby-plugin-image";
import "swiper/css/bundle";
import { PlayArrowRounded } from "@material-ui/icons";

const SwiperContent = styled(Swiper)`
  .swiper-slide {
    margin-bottom: 0em;
  }
  .swiper-pagination {
    bottom: 0px;
  }
  .swiper-pagination-bullet-active {
    background-color: #fbb400;
  }
`;

const CustomButton = styled.button`
  border: 0px;
  padding: 0.3em;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 28px 37px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
`;

const SlideImage = (props) => {
  const { imageObject, nextButtonName, nextButtonPre } = props;
  const slides = imageObject.edges.map(({ node }) => (
    <div className="image-card">
      <GatsbyImage
        style={{ border: "1px solid rgba(64,87,109,.07)", borderRadius: 5 }}
        image={node.childImageSharp.gatsbyImageData}
        alt={node.base}
      />
    </div>
  ));

  return (
    <>
      <SwiperContent
        spaceBetween={5}
        navigation={{
          nextEl: `.${nextButtonName}`,
          prevEl: `.${nextButtonPre}`,
        }}
        modules={[Navigation]}
        breakpoints={{
          640: { slidesPerView: 3 },
          400: { slidesPerView: 3 },
          390: { slidesPerView: 2 },
          200: { slidesPerView: 2 },
        }}
        style={{
          width: "100%",
          height: "100%",
          gridColumn: "2 / span 10",
          gridRow: 1,
        }}
      >
        {slides.map((slideContent, index) => (
          <SwiperSlide key={index}>{slideContent}</SwiperSlide>
        ))}
      </SwiperContent>
    </>
  );
};

const WrapperSlide = styled.div`
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  align-items: center;
  justify-items: center;
  @media (max-width: 575px) {
    .custom_next {
      grid-column: 12 / span 1;
    }
    .custom_prev {
    }
  }
`;

const SlideWithButton = (props) => {
  const { image, nextButtonName, nextButtonPre } = props;

  return (
    <>
      <WrapperSlide>
        <CustomButton
          aria-label="button-pre"
          className={nextButtonPre}
          style={{ marginRight: "0.3em" }}
        >
          <PlayArrowRounded style={{ transform: "rotate(180deg)" }} />
        </CustomButton>

        <SlideImage
          imageObject={image}
          nextButtonName={nextButtonName}
          nextButtonPre={nextButtonPre}
        />
        <CustomButton
          aria-label="button-next"
          className={nextButtonName}
          style={{ marginLeft: "0.3em" }}
        >
          <PlayArrowRounded />
        </CustomButton>
      </WrapperSlide>
    </>
  );
};

export default SlideWithButton;
