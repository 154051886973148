import React, { useRef, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import BannerVideo from "../../../../images/Mobile/VideoBanner_2.mp4";

const ContainerImage = styled.div`
  max-width: 250px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  .frame {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 5px;
    left: 0;
  }
`;

const VideoBannerComponent = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoObserver = new IntersectionObserver((entries, videoObserver) => {
      entries.forEach((video) => {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            const videoSource = video.target.children[source];

            if (
              typeof videoSource.tagName === "string" &&
              videoSource.tagName === "SOURCE"
            ) {
              videoSource.src = videoSource.dataset.src;
            }
          }
          video.target.load();
          video.target.setAttribute(
            "style",
            "border-radius: 26px; display: block; width: 100%; max-width: 229px; margin: 0 auto; height: auto;"
          );

          videoObserver.unobserve(video.target);
        }
      });
    });

    if (videoRef.current) videoObserver.observe(videoRef.current);
    return () => {
      videoObserver.disconnect();
    };
  }, []);

  return (
    <ContainerImage>
      <StaticImage
        placeholder="tracedSVG"
        imgStyle={{ zIndex: 1 }}
        quality="40"
        src="../../../../images/Mobile/iphone.png"
        alt=""
      />

      <div className="frame">
        <video
          ref={videoRef}
          loading="lazy"
          autoPlay
          muted
          loop
          playsInline
          style={{
            borderRadius: 26,
            display: "block",
            width: "100%",
            maxWidth: 229,
            margin: "0 auto",
            height: "auto",
          }}
        >
          <source data-src={BannerVideo} type="video/mp4" />
        </video>
      </div>
    </ContainerImage>
  );
};

export default VideoBannerComponent;
