import React from "react";
import styled from "@emotion/styled";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { MaxPack } from "./VideoMobile/VideoConfig";
import SlideWithButton from "./Slide/SlideWithButton";
import { VideoContainer } from "./Feature/VideoFeature";
import { WrapperContainer, WrapperContainerSlider } from "./Style/index";

const WrapperVideo = styled.div`
  display: grid;
  gap: 1em;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  order: 1;
`;

const CompellingAd = ({ order }) => {
  const { t } = useTranslation();
  const { maxPackSlide } = useStaticQuery(graphql`
    query {
      maxPackSlide: allFile(
        filter: { relativeDirectory: { eq: "Mobile/MaxPack" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 70
                width: 200
                blurredOptions: { width: 100 }
              )
            }
            base
            id
          }
        }
      }
    }
  `);

  return (
    <WrapperContainer>
      <WrapperVideo>
        <div className="card-video">
          <div className="header-card">
            <StaticImage
              placeholder="blurred"
              quality="50"
              src="../../../images/Mobile/ThumbnailCard/ThumbMaxPack.png"
              alt=""
              width={50}
            />
            <div>
              <h1>Advertising Template</h1>
              <h2>KUU Creator</h2>
            </div>
          </div>
          <VideoContainer videoName={MaxPack} />
        </div>
      </WrapperVideo>

      <WrapperContainerSlider order={order}>
        <div className="container-text">
          <h1>
            <Trans components={[<br />, <strong />]}>
              {t("app.Create Compelling advertising")}
            </Trans>
          </h1>
          <p>
            <Trans components={[<br />, <strong />]}>
              {t("app.Compose professionally")}
            </Trans>
          </p>
        </div>

        <SlideWithButton
          image={maxPackSlide}
          nextButtonName="nextAd"
          nextButtonPre="preAd"
        />
      </WrapperContainerSlider>
    </WrapperContainer>
  );
};
export default CompellingAd;
