import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import SEO from "../components/seo";
import Layout from "../components/layout/index";
import AppBanner from "../components/Mobile/components/AppBanner";
import AppPromote from "../components/Mobile/components/AppPromote";
import ProQuality from "../components/Mobile/components/ProQuality";
import CompellingAd from "../components/Mobile/components/CompellingAd";
import SocialMedia from "../components/Mobile/components/SocialMedia";
import DesignPersonal from "../components/Mobile/components/DesignPersonal";
import TitleQuote from "../components/Mobile/components/TitleQuote";
import FeatureContent, {
  PortentContent,
} from "../components/Mobile/components/Feature/FeatureContent";
import {
  Chair,
  Resizing,
  Search,
  Template,
  TextGroup,
  Cutout,
  Element,
  Harmonization,
  Animation,
  ChangeModelFaces,
  VideoEffects,
  VideoElements,
  AiDesigner,
} from "../components/Mobile/components/VideoMobile/VideoConfig";

const IndexPage = ({ location: { pathname } }) => {
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO title="Home" pathname={pathname} />
      <AppBanner />
      <ProQuality order={0} />

      <CompellingAd order={1} />

      <SocialMedia order={0} />

      <DesignPersonal order={1} />

      <TitleQuote />

      {/* -------------- AI Designer */}
      <PortentContent
        order={1}
        header={
          <h1>
            <Trans components={[<br />, <strong />]}>
              {t("app.Get product images effortlessly with 1 tap AI Designer")}
            </Trans>
          </h1>
        }
        subTitle={
          <p>
            <Trans components={[<br />, <strong />]}>
              {t("app.Take your time-consuming process")}
            </Trans>
          </p>
        }
        videoName={AiDesigner}
      />

      {/* -------------- remove background */}
      <FeatureContent
        header={
          <h1>
            <Trans components={[<br />, <strong />]}>
              {t("app.automatically")}
            </Trans>
          </h1>
        }
        subTitle={
          <p>
            <Trans components={[<br />, <strong />]}>
              {t("app.Get a precise")}
            </Trans>
          </p>
        }
        videoName={Chair}
      />

      {/* -------------- Animation */}
      <FeatureContent
        order={1}
        header={
          <h1>
            <Trans components={[<br />, <strong />]}>
              {t("app.animation")}
            </Trans>
          </h1>
        }
        subTitle={
          <p>
            <Trans components={[<br />, <strong />]}>
              {t("app.bring your creation")}
            </Trans>
          </p>
        }
        videoName={Animation}
      />

      {/* -------------- Video Elements */}
      <FeatureContent
        header={
          <h1>
            <Trans components={[<br />, <strong />]}>
              {t("app.charm everyone")}
            </Trans>
          </h1>
        }
        subTitle={
          <p>
            <Trans components={[<br />, <strong />]}>
              {t("app.get access to")}
            </Trans>
          </p>
        }
        videoName={VideoElements}
      />

      {/* -------------- Video Effect */}
      <FeatureContent
        order={1}
        header={
          <h1>
            <Trans components={[<br />, <strong />]}>
              {t("app.Create desired mood")}
            </Trans>
          </h1>
        }
        subTitle={
          <p>
            <Trans components={[<br />, <strong />]}>
              {t("app.Simply apply")}
            </Trans>
          </p>
        }
        videoName={VideoEffects}
      />

      {/* -------------- Video Change Model Faces */}
      <FeatureContent
        header={
          <h1>
            <Trans components={[<br />, <strong />]}>
              {t("app.Magically change")}
            </Trans>
          </h1>
        }
        subTitle={
          <p>
            <Trans components={[<br />, <strong />]}>
              {t("app.With a single tap")}
            </Trans>
          </p>
        }
        videoName={ChangeModelFaces}
      />

      {/* -------------- models and object cutouts */}
      <FeatureContent
        order={1}
        header={
          <h1>
            <Trans components={[<br />, <strong />]}>
              {t("app.Thousands of")}
            </Trans>
          </h1>
        }
        subTitle={
          <p>
            <Trans components={[<br />]}>{t("app.Explore our")}</Trans>
          </p>
        }
        videoName={Cutout}
      />

      {/* -------------- Awesome elements */}
      <FeatureContent
        videoName={Element}
        header={
          <h1>
            <Trans components={[<br />, <strong style={{ color: "#000" }} />]}>
              {t("app.Awesome")}
            </Trans>
          </h1>
        }
        subTitle={
          <p>
            <Trans components={[<br />]}>{t("app.Explore more")}</Trans>
          </p>
        }
      />

      {/* -------------- Cool design texts */}
      <FeatureContent
        videoName={TextGroup}
        order={1}
        header={
          <h1>
            <Trans components={[<br />, <strong style={{ color: "#000" }} />]}>
              {t("app.Cool design")}
            </Trans>
          </h1>
        }
        subTitle={
          <p>
            <Trans components={[<br />]}>{t("app.Add texts to")}</Trans>
          </p>
        }
      />

      {/* -------------- AI Harmonization */}
      <FeatureContent
        videoName={Harmonization}
        header={
          <h1>
            <Trans components={[<br />, <strong />]}>
              {t("app.AI Harmonization")}
            </Trans>
          </h1>
        }
        subTitle={
          <p>
            <Trans components={[<br />]}>{t("app.Automatically adjust")}</Trans>
          </p>
        }
      />

      {/* -------------- resize */}
      <FeatureContent
        order={1}
        videoName={Resizing}
        header={
          <h1>
            <Trans components={[<br />, <strong style={{ color: "#000" }} />]}>
              {t("app.1 Click")}
            </Trans>
          </h1>
        }
        subTitle={
          <p>
            <Trans components={[<br />]}>{t("app.Make your creation")}</Trans>
          </p>
        }
      />

      {/* -------------- Templates */}
      <FeatureContent
        videoName={Template}
        header={
          <h1>
            <Trans components={[<br />, <strong style={{ color: "#000" }} />]}>
              {t("app.Adjustable Templates")}
            </Trans>
          </h1>
        }
        subTitle={
          <p>
            <Trans components={[<br />]}>{t("app.Get inspired")}</Trans>
          </p>
        }
      />

      {/* -------------- AI Search system */}
      <FeatureContent
        order={1}
        videoName={Search}
        header={
          <h1>
            <Trans
              components={[
                <br />,
                <strong />,
                <strong style={{ color: "#000" }} />,
              ]}
            >
              {t("app.Smart AI")}
            </Trans>
          </h1>
        }
        subTitle={
          <p>
            <Trans components={[<br />]}>{t("app.Our AI search")}</Trans>
          </p>
        }
      />

      <div
        style={{
          backgroundColor: "#f4f4f4",
        }}
      >
        <AppPromote />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
