import React from "react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { Content } from "./VideoMobile/VideoConfig";
import { VideoContainer } from "./Feature/VideoFeature";
import { WrapperContainer } from "./Style/index";

const BgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  ::before {
    content: " ";
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    left: 65%;
    background-image: linear-gradient(
      to right bottom,
      #f69724,
      #f8a025,
      #faa926,
      #fcb328,
      #fdbc2b
    );

    @media (max-width: 591px) {
      content: unset;
    }
  }
`;

const WrapperVideo = styled.div`
  display: grid;
  gap: 1em;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  order: 1;
`;

const SocialMedia = ({ order }) => {
  const { t } = useTranslation();
  return (
    <WrapperContainer order={order}>
      <BgContainer>
        <div className="content">
          <WrapperVideo>
            <div className="card-video">
              <div className="header-card">
                <StaticImage
                  placeholder="blurred"
                  quality="50"
                  src="../../../images/Mobile/ThumbnailCard/ThumbContent.png"
                  alt=""
                  width={50}
                />

                <div>
                  <h1>Content Template</h1>
                  <h2>KUU Creator</h2>
                </div>
              </div>
              <VideoContainer videoName={Content} />
            </div>
          </WrapperVideo>
          <div className="container-text">
            <h1>
              <Trans components={[<br />, <strong />]}>
                {t("app.Make engaging")}
              </Trans>
            </h1>
            <p>
              <Trans components={[<br />]}>
                {t("app.Create eye-catching")}
              </Trans>
            </p>
          </div>
        </div>
      </BgContainer>
    </WrapperContainer>
  );
};

export default SocialMedia;
