import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";

const WrapperFeatureVideo = styled.div`
  .frame {
    opacity: 0;
    transition: opacity 0.7s ease-in;
    max-width: 400px;
    outline: unset;
    border-radius: ${(props) =>
      props.border ? "0em 1em 1em 0em" : "1em 0em 0em 1em"};

    @media (max-width: 732px) {
      border-radius: 1em 1em 0em 0em;
      max-width: unset;
    }
  }
`;

const VideSource = ({ videoName, paddingTop, noBorder = true }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoObserver = new IntersectionObserver((entries, videoObserver) => {
      entries.forEach((video) => {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            const videoSource = video.target.children[source];

            if (
              typeof videoSource.tagName === "string" &&
              videoSource.tagName === "SOURCE"
            ) {
              videoSource.src = videoSource.dataset.src;
            }
          }
          video.target.load();
          video.target.setAttribute(
            "style",
            `opacity: 1; display: block; width: 100%;  margin: 0 auto;  height: 100%; ${
              noBorder ? " border: 1px solid rgba(64, 87, 109, 0.07);" : ""
            } `
          );

          videoObserver.unobserve(video.target);
        }
      });
    });

    if (videoRef.current) videoObserver.observe(videoRef.current);
    return () => {
      videoObserver.disconnect();
    };
  }, [noBorder]);

  return (
    <div
      style={{
        position: "relative",
        paddingTop: paddingTop ? paddingTop : "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "100%",
        }}
      >
        <video
          ref={videoRef}
          className="frame"
          loading="lazy"
          autoPlay
          muted
          loop
          playsInline
          style={{
            display: "block",
            width: "100%",
            margin: "0 auto",
            height: "100%",
          }}
        >
          <source data-src={videoName} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export const VideoFeature = ({ videoName, border }) => {
  return (
    <WrapperFeatureVideo border={border}>
      <VideSource videoName={videoName} />
    </WrapperFeatureVideo>
  );
};

export const VideoContainer = ({ videoName, paddingTop }) => {
  return <VideSource videoName={videoName} paddingTop={paddingTop} />;
};

export const VideoPortent = ({ videoName, noBorder }) => {
  return <VideSource videoName={videoName} noBorder={noBorder} />;
};
