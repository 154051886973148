import React from "react";
import styled from "@emotion/styled";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { Product } from "./VideoMobile/VideoConfig";
import SlideWithButton from "./Slide/SlideWithButton";
import { VideoContainer } from "./Feature/VideoFeature";
import { WrapperContainer } from "./Style/index";

const WrapperVideo = styled.div`
  display: grid;
  gap: 1em;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  padding: 2em;
  position: relative;
  order: 1;
  ::before {
    content: " ";
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: ${(props) => `url(${props.background})`};
  }
`;

const ProQuality = ({ order }) => {
  const { t } = useTranslation();
  const { productSlide, backgroundOvalScreen } = useStaticQuery(graphql`
    query {
      productSlide: allFile(
        filter: { relativeDirectory: { eq: "Mobile/Product" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 70
                width: 110
                blurredOptions: { width: 100 }
              )
            }
            base
            id
          }
        }
      }
      backgroundOvalScreen: file(relativePath: { eq: "svg/MultiOval.svg" }) {
        id
        publicURL
      }
    }
  `);

  return (
    <WrapperContainer order={order}>
      <WrapperVideo background={backgroundOvalScreen.publicURL}>
        <div style={{ order: 1 }}>
          <SlideWithButton
            image={productSlide}
            nextButtonName="nextPro"
            nextButtonPre="prePro"
          />
        </div>
        <div className="card-video" style={{ order: 0 }}>
          <div className="header-card">
            <StaticImage
              placeholder="blurred"
              quality="50"
              src="../../../images/Mobile/ThumbnailCard/ThumbProduct.png"
              alt=""
              width={50}
            />
            <div>
              <h1>Product Template</h1>
              <h2>KUU Creator</h2>
            </div>
          </div>

          <VideoContainer videoName={Product} />
        </div>
      </WrapperVideo>
      <div className="container-text">
        <h1>
          <Trans components={[<br />, <strong />]}>
            {t("app.Compose pro-quality")}
          </Trans>
        </h1>
        <p>
          <Trans components={[<br />]}>{t("app.Take a product photo")}</Trans>
        </p>
      </div>
    </WrapperContainer>
  );
};

export default ProQuality;
