import styled from "@emotion/styled";

export const WrapperContainer = styled.div`
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 1em 1em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  grid-gap: 1em;

  .content {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    padding: 1em 1em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
    grid-gap: 1em;
  }

  .container-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    order: ${(props) => (props.order ? props.order : 0)};
    @media (max-width: 575px) {
      order: 1;
    }

    @media (max-width: 591px) {
      max-width: 450px;
      margin: 0 auto;
    }

    & h1 {
      font-weight: 500;
      color: #185577;
      margin: 0.5em 0;
      font-size: clamp(1.4rem, 2.5vw, 1.8rem);
      padding: 0em 0.5em;
      @media (max-width: 382px) {
        & br {
          display: none;
        }
      }
    }
    & p {
      color: #585858;
      font-weight: 400;
      margin: 0.5em 0;
      padding: 0em 0.5em;
      font-size: clamp(1rem, 2.5vw, 1.1rem);
    }
    & h5 {
      margin: 0;
      font-weight: 300;
      font-size: clamp(0.9rem, 2.5vw, 0.9rem);
    }
    @media (max-width: 592px) {
      text-align: center;
      & .app-store {
        justify-content: center;
      }
    }
  }

  .card-video {
    background-color: #fff;
    box-shadow: rgb(17 17 26 / 5%) 0px 1px 0px, rgb(17 17 26 / 10%) 0px 0px 8px;
    max-width: 350px;
    width: 100%;
    height: 100%;
    padding: 1em 1em;
    border-radius: 0.5em;
    margin: 0 auto;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 1em;
    div:nth-of-type(1) {
      order: 0;
      @media (max-width: 575px) {
        order: 0;
      }
    }
  }
  .header-card {
    gap: 1em;
    display: flex;
    align-items: center;
    & h1 {
      margin: 0;
      font-size: clamp(0.9rem, 2.5vw, 0.9rem);
    }
    & h2 {
      font-weight: 300;
      margin: 0;
      font-size: clamp(0.8rem, 2.5vw, 0.8rem);
    }
  }
  .frame {
    max-width: 340px;
    border-radius: 0.5em;
    opacity: 0;
    transition: opacity 0.7s ease-in;
  }
`;

export const WrapperContainerSlider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  max-width: 500px;
  margin: 0 auto;
  order: ${(props) => (props.order ? props.order : 0)};

  @media (max-width: 575px) {
    max-width: 400px;
    order: ${(props) => (props.order ? props.order : 0)};
  }
`;

export const BannerContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 5em;
  display: grid;
  grid-gap: 1em;
  padding: 1em;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  position: relative;
  z-index: 1;
  ::after {
    content: " ";
    background-image: ${(props) => `url(${props.background})`};
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: cover;
    position: absolute;
    width: 90%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    left: -100%;
    right: 0;
    z-index: -1;
    @media (max-width: 700px) {
      width: 100%;
    }
    @media (max-width: 592px) {
      left: -50%;
      top: 2%;
      transform: rotate(-13deg);
    }
    @media (max-width: 380px) {
      left: -35%;
      transform: rotate(-6deg);
    }
  }
  div:nth-of-type(1) {
    @media (max-width: 591px) {
      order: 1;
    }
  }
  .wrapper-image {
    max-width: 250px;
    margin: 0 auto;
  }
  .wrapper-text {
    max-width: 900px;
    margin: 0 auto;
    padding: 2em 1em;
    & h1 {
      margin: 0.5em 0em;
      background: linear-gradient(
        to left bottom,
        #f9993c,
        #fb9f33,
        #fca628,
        #fcad1a,
        #fbb400
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: clamp(1.4rem, 3vw, 2.9rem);
    }
    & h2 {
      margin: 0.5em 0em;
      font-weight: 600;
      font-size: clamp(1.3rem, 2.5vw, 1.8rem);
    }
    & .body-text-download {
      margin: 1em 0em 0em 0em;
      font-weight: 300;
      font-size: clamp(0.9rem, 2.5vw, 0.9rem);
      order: 0;
    }
    .sub-text {
      font-size: clamp(1rem, 2.5vw, 1.3rem);
      font-weight: 400;
    }

    @media (max-width: 592px) {
      text-align: center;
      & .app-store {
        justify-content: center;
      }
    }

    & .desktop-container {
      display: flex;
      align-items: end;
      gap: 0.5em;
      margin: 0.5em 0em 0em 0em;
      order: 2;
      max-width: 180px;
      width: 100%;
      @media (max-width: 960px) {
        display: none;
      }
    }
    & .divider-container {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
      height: auto;
      margin-top: 0.5em;
      order: 1;
      @media (max-width: 960px) {
        display: none;
      }
    }
    & .download-container {
      display: flex;
      gap: 0.5em;
      flex-wrap: wrap;

      @media (max-width: 592px) {
        justify-content: center;
      }
      & a {
        font-weight: 300;
        font-size: clamp(0.9rem, 2.5vw, 0.9rem);
        color: #000;
        text-decoration: none;
        :hover {
          text-decoration: underline;
        }
      }
    }
  }
`;
